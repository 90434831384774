import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IOrder, IOrderBusinessResponse } from 'src/types/Order'
import { PaginationData } from 'src/types/Paginate'
import { api } from '.'

export interface IGetTotalsResponse {
  orderTotal: number
  voucherUsed: number
  subTotal: number
  amountTransferredToBusiness: number
  totalFees: number
}

type IOrderApi = {
  get(params: PaginationData<IOrder>): Promise<IOrderBusinessResponse>
  getTotals(params: PaginationData<IOrder>): Promise<IGetTotalsResponse>
  getById(id: string): Promise<IOrder>
  downloadCsv(params: PaginationData<IOrder>): Promise<IOrderBusinessResponse>
}

const OrderApi = (): IOrderApi => {
  const orderApi = '/orders'

  const get = (params: PaginationData<IOrder>): Promise<IOrderBusinessResponse> =>
    api.get(`${orderApi}/grocers${generateParamsFromPagination(params)}`)
  const downloadCsv = (params: PaginationData<IOrder>): Promise<IOrderBusinessResponse> =>
    api.get(`${orderApi}/grocers/csv${generateParamsFromPagination(params)}`)
  const getById = (id: string): Promise<IOrder> => api.get(`${orderApi}/${id}`)
  const getTotals = (params: PaginationData<IOrder>): Promise<IGetTotalsResponse> =>
    api.get(`${orderApi}/grocers/total${generateParamsFromPagination(params)}`)

  return {
    get,
    getTotals,
    getById,
    downloadCsv,
  }
}

export default OrderApi()
