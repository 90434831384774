import * as Yup from 'yup'

export default Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  address: Yup.object({
    postcode: Yup.string().required('Postcode is required'),
    state: Yup.string().required('State is required'),
    suburb: Yup.string().required('Suburb is required'),
  }),
})
