import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import AppContext from 'src/AppContext'
import InputText from 'src/components/InputText'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { AuthInitialValue } from 'src/types/Auth'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

const ForgotPassword = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { dispatch } = React.useContext(AppContext)
  const [success, setSuccess] = React.useState<boolean>(false)

  const onSubmit = React.useCallback(
    (data: any) => {
      dispatch({ type: Types.Loading, payload: { loading: true } })

      AuthApi.forgotPassword(data)
        .then(() => {
          setSuccess(true)
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [dispatch]
  )

  const goToLogin = () => {
    navigate('/login')
  }

  return (
    <Page className={classes.root} title="Forgot Password">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm" className={classes.container}>
          {!success ? (
            <Formik initialValues={AuthInitialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box mt={3} mb={1}>
                    <Typography variant="h2"> Forgot Password </Typography>
                    <Typography variant="body2"> Please enter your email. A link will then be sent to you. </Typography>
                  </Box>

                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />

                  <Box my={2}>
                    <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                      Request password
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          ) : (
            <>
              <Box mt={3} mb={1}>
                <Typography variant="h2"> Forgot Password </Typography>
                <Typography variant="body2"> Please check your email and click on the provided link to reset your password </Typography>
              </Box>

              <Box my={2}>
                <Button color="primary" fullWidth size="large" onClick={goToLogin} variant="contained">
                  Go to Login
                </Button>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Page>
  )
}

export default ForgotPassword
