import { Box, Button, Container, makeStyles } from '@material-ui/core'
import { Stripe } from '@stripe/stripe-js'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import Page from 'src/components/Page'
import StripeLogo from 'src/components/StripeLogo'
import Toolbar from 'src/components/Toolbar'
import { validateABN } from 'src/helpers/ABNHelper'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IStore, storeInitialValue } from 'src/types/User'
import StoreForm from './StoreForm'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const CreateStore = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { state, dispatch } = React.useContext(AppContext)

  const isValidRequest = (values: IStore) => {
    if (!validateABN(values.abn.toString())) {
      AlertError('Invalid ABN')
      return false
    }
    if (!values.businessCoverFile) {
      AlertError('Please, upload your Business Cover image')
      return false
    }
    if (!values.businessLogoFile) {
      AlertError('Please, upload your Business Logo image')
      return false
    }

    return true
  }

  const onSubmit = async (values: IStore) => {
    if (!isValidRequest(values)) return

    dispatch({ type: Types.Loading, payload: { loading: true } })

    const { stripe }: { stripe: Stripe | null } = state
    let stripeToken = null
    if (stripe) {
      const result = await stripe.createToken('bank_account', {
        country: 'AU',
        currency: 'AUD',
        routing_number: values.bsb,
        account_number: values.accountNumber,
        account_holder_type: 'company',
        account_holder_name: values.businessName,
      })
      stripeToken = result.token
    }

    StoreApi.create({ ...values, bankAccountToken: stripeToken ? stripeToken.id : undefined, placeId: values.address.place_id })
      .then(() => {
        AlertSuccess('Store created successfully!')
        navigate('/app/stores')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = (useFormik({
    initialValues: storeInitialValue,
    onSubmit,
    validationSchema,
  }) as unknown) as Formik<IStore>

  return (
    <Page className={classes.root} title="Create Store">
      <Container maxWidth={false}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar
            title="Create Store"
            showBack={true}
            buttons={
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
            }
          />
          <StoreForm formik={formik} />
          <StripeLogo />
        </form>
      </Container>
    </Page>
  )
}

export default CreateStore
