import { Box, Card, CardContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { IAddress } from 'src/types/Address'
import { IOrder, IOrderItems } from 'src/types/Order'

const useStyles = makeStyles((theme) => ({
  coverContainer: {
    width: '100%',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 15,
  },
  cover: {
    width: '100%',
  },
  logo: {
    position: 'absolute',
    left: 35,
    width: 150,
    height: 150,
  },
  contactDetailsGrid: {
    minHeight: '100%',
    '& div': { minHeight: '100%' },
  },
  line: {
    marginBottom: 15,
  },
  productPictureContainer: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      height: 80,
    },
  },
  productPicture: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 100,
    },
  },
}))

const OrderForm: React.FC<{ order: IOrder }> = ({ order }) => {
  const classes = useStyles()

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h4">Order Details</Typography>
              <br />
              <Divider />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Code</b>
                  </Typography>
                  <Typography variant="body1">{order?.code}</Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Customer</b>
                  </Typography>
                  <Typography variant="body1">{order?.completeName}</Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Store</b>
                  </Typography>
                  <Typography variant="body1">
                    {order?.grocer ? `${order?.grocer.businessName} - ${(order?.grocer.address as IAddress).suburb}` : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Total</b>
                  </Typography>
                  <Typography variant="body1">{`$ ${order?.orderTotal ? order?.orderTotal.toFixed(2) : '0'}`}</Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Voucher</b>
                  </Typography>
                  <Typography variant="body1">{`$ ${order?.voucherUsed ? order?.voucherUsed.toFixed(2) : '0'}`}</Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Order Date</b>
                  </Typography>
                  <Typography variant="body1">{order?.createdAt ? moment(order?.createdAt).format('DD/MM/YYYY - HH:mm') : '-'}</Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <Typography variant="h5">
                    <b>Pickup Date</b>
                  </Typography>
                  <Typography variant="body1">{order?.collectDate ? moment(order?.collectDate).format('DD/MM/YYYY - HH:mm') : '-'}</Typography>
                </Grid>
                <Grid item xs={12} lg={12} xl={12}>
                  <Typography variant="h5">
                    <b>Store Pickup Times</b>
                  </Typography>
                  <Grid spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>From: </strong> {moment(order?.pickupTime.from).format('DD/MM/YYYY - HH:mm')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">
                        <strong>To: </strong> {moment(order?.pickupTime.to).format('DD/MM/YYYY - HH:mm')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h4">Items</Typography>
              <br />
              <Divider />
              <br />
              <Grid container spacing={2}>
                {order?.items &&
                  order?.items.map((item: IOrderItems) => (
                    <Grid item xs={12} className={classes.line} key={item.productId}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography variant="h5">Name</Typography>
                          <Typography variant="body1">{item.name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h5">Quantity</Typography>
                          <Typography variant="body1">{item.quantity}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h5">Price</Typography>
                          <Typography variant="body1">{`$ ${item.price ? item.price.toFixed(2) : '0'}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OrderForm
