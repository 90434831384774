import { IAddress } from './Address'

export interface ISignupRequest {
  firstName: string
  lastName: string
  placeId: string
  phoneNumber: string
  email: string
  referrerCode?: string
  accountManagerCode?: string
  address: Partial<IAddress>
}

export const SignupInitialValue = {
  firstName: '',
  lastName: '',
  placeId: '',
  phoneNumber: '',
  email: '',
  referrerCode: '',
  accountManagerCode: '',
  address: {
    postcode: '',
    suburb: '',
    state: '',
  },
}
