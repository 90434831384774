import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AppContext from 'src/AppContext'
import TopBar from './TopBar'

const useStyles = makeStyles(() => ({
  root: {
    // background: 'linear-gradient(140deg, rgba(43,43,43,1) 0%, rgba(20,20,20,1) 70%, rgba(0,0,0,1) 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
  content: {
    marginTop: 80,
  },
}))

const MainLayout: React.FC<{}> = () => {
  const classes = useStyles()
  const { state } = React.useContext(AppContext)
  const navigate = useNavigate()

  if (state.isAuthenticated) navigate('/app/dashboard', { replace: true })

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout
