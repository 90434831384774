import { IProductCategory } from './ProductCategory'

export interface IProduct {
  _id?: string
  grocerId: string
  name: string
  type: string
  packageType?: string
  picture?: string
  pictureFile?: File
  regularPrice: number
  grocerPrice: number
  quantityAvailable: number
  defaultQuantity: number
  dailyLimit?: number
  confirmedStockAt?: Date
  category: string
  description: string
  isActive: boolean
  categoryObj?: IProductCategory
  earliestToPost?: Date | null
  itemsInBag?: number
}

export const productInitialValue: IProduct = {
  grocerId: '',
  name: '',
  type: '',
  packageType: '',
  grocerPrice: 0,
  regularPrice: 0,
  quantityAvailable: 0,
  defaultQuantity: 0,
  category: '',
  description: '',
  isActive: true,
  earliestToPost: null,
  itemsInBag: undefined,
}

export default IProduct
