import React from 'react'
import { Navigate } from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout'
import MainLayout from './layouts/MainLayout'
import Customer from './views/Customer'
import ForgotPassword from './views/ForgotPassword'
import Login from './views/Login'
import NotFound from './views/NotFound'
import Order from './views/Order'
import ViewOrder from './views/Order/ViewOrder'
import Product from './views/Product'
import CreateProduct from './views/Product/Create'
import DuplicateProduct from './views/Product/Duplicate'
import EditProduct from './views/Product/Edit'
import Profile from './views/Profile'
import Register from './views/Register'
import RegisterConfirmation from './views/RegisterConfirmation'
import ResetPassword from './views/ResetPassword'
import Store from './views/Store'
import CreateStore from './views/Store/Create'
import EditStore from './views/Store/Edit'

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      // { path: 'dashboard', element: <Dashboard /> },
      { path: 'stores', element: <Store /> },
      { path: 'stores/new', element: <CreateStore /> },
      { path: 'stores/:id', element: <EditStore /> },

      { path: 'product', element: <Product /> },
      { path: 'product/new', element: <CreateProduct /> },
      { path: 'product/:id', element: <EditProduct /> },
      { path: 'product/:id/duplicate', element: <DuplicateProduct /> },

      { path: 'order', element: <Order /> },
      { path: 'order/:id', element: <ViewOrder /> },

      { path: 'profile', element: <Profile /> },

      { path: '*', element: <NotFound /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'customer', element: <Customer /> },
      { path: 'register', element: <Register /> },
      { path: 'register-confirmation', element: <RegisterConfirmation /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
]

export default routes
