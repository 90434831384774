import { createMuiTheme } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

export interface ITheme {
  palette: {
    primary: {
      main: string
    }
    mainBackground: {
      default: string
    }
  }
}

const theme = createMuiTheme({
  direction: 'ltr',
  palette: {
    // type: 'dark',
    primary: {
      main: '#5646cf',
    },
    secondary: {
      main: '#210f48',
    },
    text: {
      secondary: '#5e5b5a',
    },
  },

  shadows,
  typography,
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
      },
    },
    //   MuiOutlinedInput: {
    //     input: {
    //       '&:-webkit-autofill': {
    //         '-webkit-box-shadow': '0 0 0 100px #2b2b2b inset',
    //         'background-clip': 'content-box !important',
    //         color: '#ddd',
    //       },
    //       '&:-webkit-autofill:focus': {
    //         '-webkit-box-shadow': '0 0 0 100px #2b2b2b inset',
    //         'background-clip': 'content-box !important',
    //         color: '#ddd',
    //       },
    //     },
    //   },
  },
})

export default theme
