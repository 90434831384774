import { AppBar, Box, Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from 'src/AppContext'
import Logo from 'src/components/Logo'
import { Types } from 'src/reducers/AppReducer'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    color: '#2b2b2b',
    height: 64,
    borderBottom: '1px solid #ddd',
  },
  logoContainer: {
    padding: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    '& h3': {
      color: '#2b2b2b',
      marginLeft: 15,
    },
  },
  avatar: {
    width: 60,
    height: 60,
  },
}))

const TopBar: React.FC<{ className?: string; onMobileNavOpen(): any }> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()

  const handleLogout = React.useCallback(() => {
    dispatch({
      type: Types.Logout,
      payload: {},
    })

    navigate('/login')
  }, [dispatch, navigate])

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <Logo />
          <Typography variant="body1"> &nbsp; Foody Bag Business </Typography>
        </div>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" onClick={handleLogout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
