import { Box, Button, Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import CustomDatatable from 'src/components/CustomDatatable'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import { IStore } from 'src/types/User'
import tableColumns from './tableColumns'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const Store = () => {
  const classes = useStyles()
  const [storeList, setStoreList] = React.useState<IStore[]>([])
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.get()
      .then((data: IStore[]) => {
        setStoreList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setStoreList])

  const handleClick = (id: string) => navigate(id)
  const handleAdd = () => navigate('new')

  return (
    <Page className={classes.root} title="Store">
      <Container maxWidth={false}>
        <Toolbar
          title="Stores"
          buttons={
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={handleAdd}>
                Create Store
              </Button>
            </Box>
          }
        />
        <Box mt={3}>
          <CustomDatatable list={storeList || []} onClick={handleClick} tableColumns={tableColumns} download={false} />
        </Box>
      </Container>
    </Page>
  )
}

export default Store
