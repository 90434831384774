import axios from 'axios'
import { AlertError } from 'src/components/Alert'
import config from 'src/config'

export const api = axios.create({ baseURL: config.apiUrl })

api.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem('fbToken'),
    }
    return config
  },
  (err) => {
    throw err.response
  }
)

api.interceptors.response.use(
  (result) => {
    return result.data
  },
  async (err) => {
    if (!err.response || err.response.status === 404) {
      AlertError('There was a problem connecting to the server. Please try again later')
    } else {
      AlertError(err.response.data.error)
      throw err.response.data.error
    }
  }
)
