import { IProduct } from 'src/types/Product'
import { api } from '.'

type IProductApi = {
  get(): Promise<IProduct[]>
  getById(id: string): Promise<IProduct>
  create(product: IProduct): Promise<IProduct>
  update(product: IProduct): Promise<IProduct>
  destroy(productId: string): Promise<{ success: boolean }>
  duplicate(productId: string, grocerDestinationIds: string[]): Promise<{ success: boolean }>
}

const ProductApi = (): IProductApi => {
  const productApi = '/products'

  const get = (): Promise<IProduct[]> => api.get(`${productApi}/stock/business-owner`)
  const getById = (id: string): Promise<IProduct> => api.get(`${productApi}/${id}`)
  const create = (product: IProduct): Promise<IProduct> => api.post(productApi, product)
  const update = (product: IProduct): Promise<IProduct> => api.patch(`${productApi}/${product._id}`, { ...product, _id: undefined })
  const destroy = (productId: string): Promise<{ success: boolean }> => api.delete(`${productApi}/${productId}`)
  const duplicate = (productId: string, grocerDestinationIds: string[]): Promise<{ success: boolean }> =>
    api.post(`${productApi}/${productId}/duplicate`, { grocerDestinationIds })

  return {
    get,
    getById,
    create,
    update,
    destroy,
    duplicate,
  }
}

export default ProductApi()
