export const validateABN = (value: string) => {
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
  const abn = value.replace(/[^\d]/, '')
  let result = false

  // check length is 11 digits
  if (abn.length === 11) {
    // apply ato check method
    let sum = 0
    let weight

    for (let index = 0; index <= weights.length - 1; index++) {
      weight = weights[index]
      let digit = parseInt(abn.substring(index, index + 1)) - (index ? 0 : 1)
      sum += weight * digit
    }

    result = sum % 89 === 0
  }

  return result
}
