import React from 'react'
import { toast } from 'react-toastify'

const spanStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const warning = {
  color: '#2b2b2b',
}

export const AlertSuccess = (message: string, options?: any) => {
  toast.success(<span style={spanStyle}> {message} </span>, options || {})
}

export const AlertWarning = (message: string, options?: any) => {
  toast.warning(<span style={{ ...spanStyle, ...warning }}> {message} </span>, options || {})
}

export const AlertError = (message: string, options?: any) => {
  toast.error(<span style={spanStyle}> {message} </span>, { ...options, autoClose: false } || { autoClose: false })
}

export const dismissAllAlerts = () => {
  toast.dismiss()
}
