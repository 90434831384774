import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Page from 'src/components/Page'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 80px)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  option: {
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '25px!important',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 5,
    boxShadow: 'rgba(86, 64, 210, 0.24) 0px 3px 8px',
    '&:hover': {
      boxShadow: 'rgba(86, 64, 210, 0.5) 0px 3px 8px',
    },
    cursor: 'pointer',
    minHeight: '25rem',
    minWidth: '40rem',
    width: '100%',
    margin: 15,
  },
  imageContainer: {
    width: '100%',
    height: '20rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBusiness: {
    width: '15rem',
  },
  imageCustomer: {
    width: '12rem',
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}))

const Register = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClickBusiness = () => navigate('/register-confirmation')
  const handleClickCustomer = () => navigate('/customer')

  return (
    <Page className={classes.root} title="Become a partner">
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12} md={4} className={classes.option} onClick={handleClickBusiness}>
          <Typography variant="h4">
            I want to{' '}
            <span className={classes.highlight}>
              <b>sell</b>
            </span>{' '}
            my end of day products
          </Typography>
          <div className={classes.imageContainer}>
            <img src="/static/t2_free_image3.png" className={classes.imageBusiness} alt="business" />
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={classes.option} onClick={handleClickCustomer}>
          <Typography variant="h4">
            I want to{' '}
            <span className={classes.highlight}>
              <b>buy</b>
            </span>{' '}
            delicious products
          </Typography>
          <div className={classes.imageContainer}>
            <img src="/static/t2_free_image1.png" className={classes.imageCustomer} alt="customer" />
          </div>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Register
