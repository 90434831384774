import { Box, Card, CardContent, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { ImageTwoTone } from '@material-ui/icons'
import { TimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import imageCompression from 'browser-image-compression'
import React from 'react'
import { DropzoneState, useDropzone } from 'react-dropzone'
import ProductCategoryApi from 'src/api/productCategory'
import AppContext from 'src/AppContext'
import { AlertError } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import NumberFormatCustom from 'src/components/NumberFormatCustom'
import productPackageTypeEnum from 'src/enum/productPackageTypeEnum'
import productTypeEnum from 'src/enum/productTypeEnum'
import { fileToBase64 } from 'src/helpers/base64Helper'
import { enumKeys, getEnumValue, getKeyEnum } from 'src/helpers/enumHelper'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IProduct } from 'src/types/Product'
import { IProductCategory } from 'src/types/ProductCategory'
import { IStore } from 'src/types/User'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '15rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#5646cf',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#5646cf',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
    marginTop: 10,
  },
  uploadSection: {
    overflow: 'hidden',
  },
  fileUpload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 10,
  },
  fileNotFound: {
    backgroundColor: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10rem',
    height: '10rem',
    borderRadius: 20,
    '& svg': {
      fontSize: '5em',
      opacity: 0.2,
    },
  },
  previewContainer: {
    height: '10rem',
    width: '10rem',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 20,
    alignItems: 'center',
    marginBottom: 15,
  },
  preview: {
    maxHeight: '100%',
  },
  logo: {
    borderRadius: 20,
  },
}))

const ProductForm: React.FC<{ formik: Formik<IProduct>; storeList: IStore[] }> = ({ formik, storeList }) => {
  const [productCategoryList, setProductCategoryList] = React.useState<IProductCategory[]>([])
  const { dispatch } = React.useContext(AppContext)
  const [imagesPreview, setImagesPreview] = React.useState<{ picture?: string }>({
    picture: '',
  })

  const classes = useStyles()

  const { setFieldValue, errors, touched, handleBlur, handleChange, values } = formik

  React.useEffect(() => {
    ProductCategoryApi.get()
      .then((res) => {
        if (res) setProductCategoryList(res)
      })
      .catch((err: Error) => console.log(err))
  }, [dispatch])

  const pictureDropzone = useDropzone({ accept: 'image/*', multiple: false })

  const compressAndSetPreview = React.useCallback(
    (acceptedFiles, type) => {
      if (acceptedFiles) {
        dispatch({ type: Types.Loading, payload: { loading: true } })
        const file = acceptedFiles[0]
        const options = {
          maxSizeMB: 100,
          useWebWorker: true,
        }

        if (file) {
          imageCompression(file, options).then(async (image) => {
            setFieldValue(`${type}File`, {
              data: await fileToBase64(file),
              filetype: file.type,
            })

            setImagesPreview((prev) => ({
              ...prev,
              [type]: URL.createObjectURL(image),
            }))
            dispatch({ type: Types.Loading, payload: { loading: false } })
          })
        } else {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        }
      }
    },
    [dispatch, setFieldValue]
  )

  React.useEffect(() => {
    compressAndSetPreview(pictureDropzone.acceptedFiles, 'picture')
  }, [pictureDropzone.acceptedFiles, compressAndSetPreview])

  React.useEffect(() => {
    if (hasErrorMessage([pictureDropzone])) {
      let message = 'Invalid File'
      if (pictureDropzone.fileRejections.length > 0) message = getErrorMessage(pictureDropzone)
      AlertError(message)
    }
  }, [pictureDropzone])

  React.useEffect(() => {
    if (values._id && values.picture) {
      setImagesPreview({
        picture: values.picture,
      })
    }
  }, [values._id, values.picture])

  React.useEffect(() => {
    if (values.type === getKeyEnum(productTypeEnum.ONE_ITEM, productTypeEnum)) setFieldValue('packageType', '')
  }, [values.type, setFieldValue])

  const getErrorMessage = (dropzoneFile: DropzoneState) => {
    return dropzoneFile.fileRejections[0].errors[0].message
  }

  const hasErrorMessage = (dropzoneFiles: DropzoneState[]) => {
    return dropzoneFiles.map((value) => value.fileRejections.length > 0).indexOf(true) > -1
  }

  const handleChangeTimePicker = (date: MaterialUiPickersDate) => {
    setFieldValue('earliestToPost', date?.toDate())
  }

  return (
    <Box mt={3}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <CardContent className={classes.fileUpload}>
                {imagesPreview.picture ? (
                  <div className={classes.previewContainer}>
                    <img src={imagesPreview.picture} className={`${classes.preview} ${classes.logo}`} alt="logo" />
                  </div>
                ) : (
                  <div className={`${classes.preview} ${classes.fileNotFound}`}>
                    <ImageTwoTone />
                  </div>
                )}
                <div {...pictureDropzone.getRootProps()} className={classes.container}>
                  <input {...pictureDropzone.getInputProps()} />
                  <Typography variant="body2">
                    Drag 'n' drop a file here <br /> or click to select files
                  </Typography>
                </div>
              </CardContent>
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <InputText
                    select
                    errors={errors}
                    touched={touched}
                    label="Store"
                    name="grocerId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.grocerId}
                  >
                    <MenuItem value="">None</MenuItem>
                    {storeList.map((store: IStore) => (
                      <MenuItem key={store._id} value={store._id}>
                        {store.businessName} - {store.suburb}
                      </MenuItem>
                    ))}
                  </InputText>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    select
                    errors={errors}
                    touched={touched}
                    label="Type"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.type}
                  >
                    <MenuItem value="">None</MenuItem>
                    {enumKeys(productTypeEnum).map((key: string) => (
                      <MenuItem key={key} value={key}>
                        {getEnumValue(key, productTypeEnum)}
                      </MenuItem>
                    ))}
                  </InputText>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputText
                    select
                    errors={errors}
                    touched={touched}
                    label="Food Type"
                    name="packageType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.packageType}
                  >
                    <MenuItem value="">None</MenuItem>
                    {enumKeys(productPackageTypeEnum).map((key: string) => (
                      <MenuItem key={key} value={key}>
                        {getEnumValue(key, productPackageTypeEnum)}
                      </MenuItem>
                    ))}
                  </InputText>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Regular Price"
                    name="regularPrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.regularPrice}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        fixedDecimalScale: true,
                        decimalScale: 2,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Foody Bag Price"
                    name="grocerPrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.grocerPrice}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        fixedDecimalScale: true,
                        decimalScale: 2,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Quantity available"
                    name="quantityAvailable"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quantityAvailable}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Default Quantity"
                    name="defaultQuantity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.defaultQuantity}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <InputText
                    select
                    errors={errors}
                    touched={touched}
                    label="Category"
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category}
                  >
                    <MenuItem value="">None</MenuItem>
                    {productCategoryList.map((pc: IProductCategory) => (
                      <MenuItem key={pc._id} value={pc._id}>
                        {pc.name}
                      </MenuItem>
                    ))}
                  </InputText>
                </Grid>

                <Grid item xs={12} lg={values.type === 'PACKAGE' ? 2 : 3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Daily limit per user"
                    name="dailyLimit"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.dailyLimit}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>

                {values.type === 'PACKAGE' && (
                  <Grid item xs={12} lg={2}>
                    <InputText
                      errors={errors}
                      touched={touched}
                      label="Number of items in the bag"
                      name="itemsInBag"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.itemsInBag}
                      shrink={true}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} lg={values.type === 'PACKAGE' ? 2 : 3}>
                  <TimePicker
                    style={{ width: '100%' }}
                    defaultValue={null}
                    label="Earliest time to post"
                    name="earliestToPost"
                    onBlur={handleBlur}
                    onChange={handleChangeTimePicker}
                    value={values.earliestToPost || null}
                    variant="dialog"
                    inputVariant="outlined"
                    views={['hours', 'minutes']}
                    clearable
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ProductForm
