import * as Yup from 'yup'

export default Yup.object().shape({
  legalBusinessName: Yup.string().required('Legal Business Name is required'),
  businessName: Yup.string().required('Trading Name is required'),
  contactName: Yup.string().required('Contact Name is required'),
  contactEmail: Yup.string().email('Contact Email must be a valid email').required('Contact Email is required'),
  statementEmail: Yup.string().email('Statement Email must be a valid email').required('Statement Email is required'),
  businessType: Yup.string().required('Business Type is required'),
  businessPhoneNumber: Yup.string().required('Phone Number is required').min(9, 'Invalid Phone Number').max(9, 'Invalid Phone Number'),
  businessDescription: Yup.string().required('Description is required'),
  abn: Yup.string().required('ABN is required'),
  addressTemp: Yup.string().required('Address is required'),
  bsb: Yup.string().required('BSB is required'),
  accountNumber: Yup.string().required('Account Number is required'),
})
