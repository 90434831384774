import { Button, Card, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import { AlertError } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import { Types } from 'src/reducers/AppReducer'
import { initialOrderFilter, IOrderFilter } from 'src/types/Order'
import { IStore } from 'src/types/User'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    height: 0,
    transition: '1s ease all',
  },
  form: {
    width: '100%',
    padding: '0px 10px',
  },
  show: {
    marginBottom: 20,
    paddingBottom: 10,
    height: 'auto',
    transition: '1s ease all',
  },
  submitButton: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const OrderFilter: React.FC<{ showFilter: boolean; onSubmit: (filter: IOrderFilter) => void }> = ({ showFilter = false, onSubmit }) => {
  const classes = useStyles()
  const { dispatch } = React.useContext(AppContext)
  const [grocerList, setGrocerList] = React.useState<IStore[]>([])

  const { errors, touched, values, setFieldValue, handleBlur, handleChange, resetForm, handleSubmit } = useFormik({
    initialValues: initialOrderFilter,
    onSubmit,
  })

  const getGrocers = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.get()
      .then((res) => {
        if (res) setGrocerList(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch])

  const handleChangeGrocer = React.useCallback(
    (_event: any, newValue: IStore[]) => {
      setFieldValue(
        'grocerList',
        newValue.map((store: IStore) => store._id)
      )
    },
    [setFieldValue]
  )

  const resetFilterForm = React.useCallback(() => {
    resetForm()
    handleSubmit()
  }, [resetForm, handleSubmit])

  const handleDateChange = (date: MaterialUiPickersDate, name: string) => {
    if (values.dateFrom && values.dateTo && moment(values.dateTo).diff(moment(values.dateFrom), 'day') < 0) {
      AlertError('Date To must be greater or equal Date from')
      return
    }
    handleChange({ currentTarget: { name, value: date ? date.toDate() : null } })
  }

  React.useEffect(() => {
    getGrocers()
  }, [getGrocers])

  return (
    <Card className={clsx([classes.root, showFilter ? classes.show : ''])}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <InputText
              errors={errors}
              touched={touched}
              label="Customer"
              name="customer"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.customer}
              size="small"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              format="DD/MM/YYYY"
              margin="dense"
              label="Date from"
              name="dateFrom"
              inputVariant="outlined"
              value={values.dateFrom}
              onChange={(date) => handleDateChange(date, 'dateFrom')}
              KeyboardButtonProps={{
                'aria-label': 'date from',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              format="DD/MM/YYYY"
              margin="dense"
              label="Date to"
              name="dateTo"
              inputVariant="outlined"
              value={values.dateTo}
              onChange={(date) => handleDateChange(date, 'dateTo')}
              KeyboardButtonProps={{
                'aria-label': 'date to',
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Autocomplete
              multiple
              limitTags={2}
              options={grocerList || []}
              onChange={handleChangeGrocer}
              getOptionLabel={(option: IStore) => `${option.businessName} - ${option.suburb}`}
              renderInput={(params) => (
                <InputText {...params} label="Stores" name="grocerList" value={values.grocerList} size="small" margin="dense" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.submitButton}>
            <Button color="primary" variant="contained" type="submit" fullWidth>
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={2} className={classes.submitButton}>
            <Button color="primary" variant="outlined" onClick={resetFilterForm} fullWidth>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}

export default OrderFilter
