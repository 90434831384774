import { Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import OrderApi from 'src/api/order'
import AppContext from 'src/AppContext'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import { IOrder, orderInitialValue } from 'src/types/Order'
import OrderForm from './OrderForm'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const ViewOrder = () => {
  const classes = useStyles()
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const [order, setOrder] = React.useState<IOrder>(orderInitialValue)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    OrderApi.getById(params.id as string)
      .then((res) => setOrder(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  return (
    <Page className={classes.root} title="View Order">
      <Container maxWidth={false}>
        <Toolbar title="View Order" showBack={true} />
        <OrderForm order={order} />
      </Container>
    </Page>
  )
}

export default ViewOrder
