import { MUIDataTableColumn } from 'mui-datatables'

const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Code',
    name: 'code',
  },
  {
    label: 'Name',
    name: 'businessName',
  },
  {
    label: 'Contact Name',
    name: 'contactName',
  },
  {
    label: 'Email',
    name: 'contactEmail',
  },
  {
    label: 'Phone Number',
    name: 'businessPhoneNumber',
  },
  {
    label: 'Active',
    name: 'active',
    options: {
      customBodyRender: (value: boolean) => (value ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Visible',
    name: 'isVisible',
    options: {
      customBodyRender: (value: boolean) => (value ? 'Yes' : 'No'),
    },
  },
  {
    label: 'Approved',
    name: 'approved',
    options: {
      customBodyRender: (value: boolean) => (value ? 'Yes' : 'No'),
    },
  },
]

export default tableColumns
