import { MUIDataTableColumn } from 'mui-datatables'
import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import { getEnumValue } from 'src/helpers/enumHelper'

const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Code',
    name: 'code',
  },
  {
    label: 'Store',
    name: 'grocerName',
    options: {
      customBodyRender: (value: string) => value || '-',
    },
  },
  {
    label: 'Customer',
    name: 'completeName',
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      customBodyRender: (value: string) => (value ? getEnumValue(value, OrderStatusEnum) : '-'),
    },
  },
  {
    label: 'Order total',
    name: 'orderTotal',
    options: {
      customBodyRender: (value: number) => `$ ${value?.toFixed(2) || 0}`,
    },
  },
  {
    label: 'Fees',
    name: 'fees',
    options: {
      customBodyRender: (value: number) => `$ ${value?.toFixed(2) || 0}`,
    },
  },
  {
    label: 'Gst/Fees',
    name: 'gst',
    options: {
      customBodyRender: (value: number) => `$ ${value?.toFixed(2) || 0}`,
    },
  },
  {
    label: 'Voucher',
    name: 'voucherUsed',
    options: {
      customBodyRender: (value: number) => `$ ${value?.toFixed(2) || 0}`,
    },
  },
  {
    label: 'Total',
    name: 'subTotal',
    options: {
      customBodyRender: (value: number) => `$ ${value.toFixed(2)}`,
    },
  },
  {
    label: 'Date',
    name: 'createdAt',
    options: {
      customBodyRender: (value: string) =>
        new Intl.DateTimeFormat('en-AU', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).format(
          new Date(value)
        ),
    },
  },
]

export default tableColumns
