import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Logo from 'src/components/Logo'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    color: '#2b2b2b',
    height: 64,
    borderBottom: '1px solid #ddd',
  },
  toolbar: {
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    padding: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    '& h3': {
      color: '#2b2b2b',
      marginLeft: 15,
    },
  },
})

const TopBar: React.FC<{ className?: string }> = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar color="transparent" className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <div className={classes.logoContainer}>
            <Logo />
            <Typography variant="body1"> &nbsp; Foody Bag Business </Typography>
          </div>
        </RouterLink>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
