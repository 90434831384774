import { IGetTotalsResponse } from 'src/api/order'
import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import IUser, { IStore } from './User'
import { IVoucher } from './Voucher'

export interface IOrder {
  _id?: string
  id: string
  status: OrderStatusEnum
  completeName: string
  orderTotal: number
  voucherId?: string
  gst: number
  paymentMethod: string
  receiptNumber: string
  voucherUsed?: number
  creditsUsed?: number
  qrCode?: string
  code: string
  history: IOrderHistory[]
  items: IOrderItems[]
  pickupTime: {
    from: Date
    to: Date
  }
  collectDate: Date
  hasReview: boolean
  user?: IUser
  voucher?: IVoucher
  grocer?: IStore
  createdAt?: Date
}

export interface IOrderItems {
  productId: string
  price: number
  quantity: number
  name: string
  description: string
  picture: string
}

export interface IOrderHistory {
  status: string
  comment: string
  createdAt: Date
}

export interface IOrderBusinessResponse {
  data: IOrder[]
  total: number
}

export interface IOrderFilter {
  grocerList?: string[]
  customer?: string
  dateFrom?: Date | null
  dateTo?: Date | null
}

export const initialOrderFilter = {
  grocerList: [],
  dateFrom: null,
  dateTo: null,
}

export const inititalOrderBusinessResponse = {
  data: [],
  total: 0,
}

export const orderInitialValue: IOrder = {
  id: '',
  status: OrderStatusEnum.READY,
  completeName: '',
  orderTotal: 0,
  gst: 0,
  paymentMethod: '',
  receiptNumber: '',
  voucherUsed: 0,
  creditsUsed: 0,
  code: '',
  history: [],
  items: [],
  pickupTime: {
    from: new Date(),
    to: new Date(),
  },
  collectDate: new Date(),
  hasReview: false,
} as IOrder

export const initialOrderTotalResponse: IGetTotalsResponse = {
  orderTotal: 0,
  voucherUsed: 0,
  subTotal: 0,
  amountTransferredToBusiness: 0,
  totalFees: 0,
}
