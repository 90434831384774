import { Box, Button, Container, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import UserApi from 'src/api/user'
import AppContext from 'src/AppContext'
import { AlertSuccess } from 'src/components/Alert'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import IUser, { businessOwnerInitialValue } from 'src/types/User'
import ProfileForm from './ProfileForm'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const Profile = () => {
  const classes = useStyles()
  const { state, dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IUser) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UserApi.update({ ...values, placeId: values.address && values.address.place_id })
      .then((user: IUser) => {
        AlertSuccess('Profile updated successfully!')
        dispatch({ type: Types.SetUser, payload: { user } })
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: businessOwnerInitialValue,
    onSubmit,
    validationSchema,
  }) as unknown as Formik<IUser>

  const { setValues } = formik

  React.useEffect(() => {
    try {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      if (state.user) setValues(state.user)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch({ type: Types.Loading, payload: { loading: false } })
    }
  }, [dispatch, setValues, state.user])

  return (
    <Page className={classes.root} title="Profile">
      <Container maxWidth={false}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar
            title="Profile"
            buttons={
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
            }
          />
          <ProfileForm formik={formik} />
        </form>
      </Container>
    </Page>
  )
}

export default Profile
