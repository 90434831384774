import { Box, Button, Container, Dialog, DialogActions, DialogContent, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { getIn, useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import AddressApi from 'src/api/address'
import SignupRequestApi from 'src/api/signupRequest'
import AppContext from 'src/AppContext'
import { dismissAllAlerts } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import NumberFormatCustom from 'src/components/NumberFormatCustom'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import AddressSearchResponse from 'src/types/AddressSearchResponse'
import { SignupInitialValue } from 'src/types/SignupRequest'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 80px)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formTitle: {
    width: '100%',
    textAlign: 'left',
    marginTop: '2em',
    marginBottom: 15,
  },

  register: {
    '& span': {
      color: theme.palette.primary.main,
    },
    marginBottom: 45,
  },

  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'green',
    backgroundColor: '#ddd',
    borderRadius: '50%',
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const RegisterConfirmation = () => {
  const classes = useStyles()
  const location = useLocation()
  const { dispatch } = React.useContext(AppContext)
  const [open, setOpen] = React.useState(false)
  const [suburbList, setSuburbList] = React.useState<string[]>([])
  const [stateList, setStateList] = React.useState<string[]>([])
  const navigate = useNavigate()

  const params = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const handleClose = React.useCallback(() => {
    setOpen(false)
    navigate('/login')
  }, [navigate])

  const onSubmit = React.useCallback(
    (data: any) => {
      dismissAllAlerts()
      dispatch({ type: Types.Loading, payload: { loading: true } })
      SignupRequestApi.create({
        ...data,
        accountManagerCode: data.accountManagerCode ? data.accountManagerCode.toUpperCase() : undefined,
        phoneNumber: `+61${data.phoneNumber}`,
        timezone: moment().utcOffset() / 60,
      })
        .then(({ success }) => {
          if (success) {
            setOpen(true)
            resetForm()
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    // eslint-disable-next-line
    [dispatch]
  )

  const { errors, touched, values, resetForm, setFieldValue, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: SignupInitialValue,
    onSubmit,
    validationSchema,
  })

  React.useEffect(() => {
    if (values.address.postcode.toString().length === 4) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AddressApi.getByPostcode(values.address.postcode)
        .then((result: { data: AddressSearchResponse }) => {
          setStateList(Array.from(new Set(result.data.postalcodes.map((address) => address.adminCode1))))
          setSuburbList(Array.from(new Set(result.data.postalcodes.map((address) => address.placeName))))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    }
  }, [values.address.postcode, dispatch])

  React.useEffect(() => {
    const referralCode = params.get('referral')
    if (referralCode) setFieldValue('referrerCode', referralCode)
  }, [params, setFieldValue])

  return (
    <Page className={classes.root} title="Become a partner">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="xs" className={classes.container}>
          <div className={classes.formTitle}>
            <Typography variant="h3">Become a merchant partner:</Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="First Name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Last Name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Phone Number"
                  name="phoneNumber"
                  shrink={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      format: '+61 ### ### ###',
                      mask: '_',
                    },
                  }}
                  value={values.phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
              </Grid>
              <Grid item xs={6}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Postcode"
                  name="address.postcode"
                  shrink={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  getIn={getIn}
                  value={values.address.postcode}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      format: '####',
                      mask: '_',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputText
                  select
                  errors={errors}
                  touched={touched}
                  label="State"
                  name="address.state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  getIn={getIn}
                  value={values.address.state}
                >
                  <MenuItem value="">None</MenuItem>
                  {stateList.map((state: string) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </InputText>
              </Grid>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Suburb"
                  name="address.suburb"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  getIn={getIn}
                  value={values.address.suburb}
                  select
                >
                  <MenuItem value="">None</MenuItem>
                  {suburbList.map((suburb: string) => (
                    <MenuItem key={suburb} value={suburb}>
                      {suburb}
                    </MenuItem>
                  ))}
                </InputText>
              </Grid>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Referral Code (Optional)"
                  name="referrerCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.referrerCode}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText
                  errors={errors}
                  touched={touched}
                  label="Account Manager Code (Optional)"
                  name="accountManagerCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.accountManagerCode}
                />
              </Grid>
              <br />
              <br />
              <Grid item xs={12}>
                <Typography variant="body2">
                  By clicking “Submit”, you acknowledge you agree to our{' '}
                  <a href="http://docs.foodybag.com.au/terms-foody-bag-business.pdf" rel="noreferrer" target="_blank">
                    Terms and Conditions
                  </a>
                  .
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
                <Box my={2}>
                  <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>

          <hr style={{ width: '100%', color: '#ddd', margin: '25px 0' }} />

          <Typography variant="body1" className={classes.register}>
            Already have an account?
            <RouterLink to="/login">
              <span>{` Sign-in`}</span>
            </RouterLink>
          </Typography>
        </Container>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.iconGrid}>
              <div className={classes.icon}>
                <Check />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">Welcome to Foody Bag! 😃</Typography>
              <br />
              <Typography variant="body1">We sent a secure password to your email.</Typography>
              <Typography variant="body1">By clicking on "Close" you will be redirected to the login page.</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default RegisterConfirmation
