import { Box, Card, CardContent, Grid, MenuItem } from '@material-ui/core'
import { getIn } from 'formik'
import React from 'react'
import AddressApi from 'src/api/address'
import AppContext from 'src/AppContext'
import InputText from 'src/components/InputText'
import NumberFormatCustom from 'src/components/NumberFormatCustom'
import { Types } from 'src/reducers/AppReducer'
import AddressSearchResponse from 'src/types/AddressSearchResponse'
import { Formik } from 'src/types/Formik'
import IUser from 'src/types/User'

const ProfileForm: React.FC<{ formik: Formik<IUser> }> = ({ formik }) => {
  const { dispatch } = React.useContext(AppContext)
  const [suburbList, setSuburbList] = React.useState<string[]>([])
  const [stateList, setStateList] = React.useState<string[]>([])

  const { errors, touched, handleBlur, handleChange, values } = formik

  React.useEffect(() => {
    if (values.address.postcode && values.address.postcode.toString().length === 4) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AddressApi.getByPostcode(values.address.postcode)
        .then((result: { data: AddressSearchResponse }) => {
          setStateList(Array.from(new Set(result.data.postalcodes.map((address) => address.adminCode1))))
          setSuburbList(Array.from(new Set(result.data.postalcodes.map((address) => address.placeName))))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    }
  }, [values.address.postcode, dispatch])

  return (
    <Box mt={3}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <InputText
                errors={errors}
                touched={touched}
                label="First Name"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputText
                errors={errors}
                touched={touched}
                label="Last Name"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputText
                errors={errors}
                touched={touched}
                label="Phone Number"
                name="phoneNumber"
                shrink={true}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    format: '+61 ### ### ###',
                    mask: '_',
                  },
                }}
                value={values.phoneNumber}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputText
                errors={errors}
                touched={touched}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <InputText
                errors={errors}
                touched={touched}
                label="Postcode"
                name="address.postcode"
                shrink={true}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address.postcode}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    format: '####',
                    mask: '_',
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <InputText
                select
                errors={errors}
                touched={touched}
                label="State"
                name="address.state"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address.state || ''}
                getIn={getIn}
              >
                <MenuItem value="">None</MenuItem>
                {stateList.map((state: string) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </InputText>
            </Grid>
            <Grid item xs={3}>
              <InputText
                errors={errors}
                touched={touched}
                label="Suburb"
                name="address.suburb"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address.suburb || ''}
                select
              >
                <MenuItem value="">None</MenuItem>
                {suburbList.map((suburb: string) => (
                  <MenuItem key={suburb} value={suburb}>
                    {suburb}
                  </MenuItem>
                ))}
              </InputText>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ProfileForm
