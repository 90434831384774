import {
  AppBar,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ImageTwoTone } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import imageCompression from 'browser-image-compression'
import React from 'react'
import { DropzoneState, useDropzone } from 'react-dropzone'
import SwipeableViews from 'react-swipeable-views'
import AddressApi from 'src/api/address'
import BusinessTypeApi from 'src/api/businessType'
import AppContext from 'src/AppContext'
import { AlertError } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import NumberFormatCustom from 'src/components/NumberFormatCustom'
import TabPanel from 'src/components/TabPanel'
import BringOwnBagEnum from 'src/enum/BringOwnBagEnum'
import { enumKeys } from 'src/helpers/enumHelper'
import { Types } from 'src/reducers/AppReducer'
import { IGoogleAddressResponse } from 'src/types/Address'
import { IBusinessType } from 'src/types/BusinessType'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import { debounce } from 'throttle-debounce'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '15rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#5646cf',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#5646cf',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
  },
  uploadSection: {
    overflow: 'hidden',
  },
  fileUpload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 10,
  },
  fileNotFound: {
    backgroundColor: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    '& svg': {
      fontSize: '5em',
      opacity: 0.2,
    },
  },
  preview: {
    height: '10rem',
    maxWidth: '100%',
    marginBottom: 25,
  },
  logo: {
    borderRadius: '50%',
  },
  radioLabel: {
    fontSize: '0.75rem',
  },
}))

const StoreForm: React.FC<{ formik: Formik<IStore> }> = ({ formik }) => {
  const [addressList, setAddressList] = React.useState<IGoogleAddressResponse[]>([])
  const [businessTypeList, setBusinessTypeList] = React.useState<IBusinessType[]>([])
  const { dispatch } = React.useContext(AppContext)
  const [tab, setTab] = React.useState(0)
  const [imagesPreview, setImagesPreview] = React.useState<{ businessLogo?: string; businessCover?: string }>({
    businessLogo: '',
    businessCover: '',
  })
  const theme: Theme = useTheme()
  const classes = useStyles()

  const { setFieldValue, errors, touched, handleBlur, handleChange, values } = formik

  const handleChangeAddress = React.useCallback(
    (_e: any, newValue: IGoogleAddressResponse | null) => {
      if (newValue) setFieldValue('address', { description: newValue.description, place_id: newValue.placeId })
    },
    [setFieldValue]
  )

  const getAddress = React.useMemo(
    () =>
      debounce(800, (address?: string) => {
        if (!address) return false
        if (address.length <= 3) return false
        AddressApi.getByAddress(encodeURIComponent(address))
          .then((res: IGoogleAddressResponse[]) => {
            setAddressList(res)
          })
          .catch((err: Error) => console.log(err))
      }),
    []
  )

  React.useEffect(() => {
    if (values.address) {
      setAddressList([values.address as IGoogleAddressResponse])
      setFieldValue('addressTemp', values.address.description)
    }
  }, [values.address, setFieldValue])

  React.useEffect(() => {
    BusinessTypeApi.get()
      .then((res) => {
        if (res) setBusinessTypeList(res)
      })
      .catch((err: Error) => console.log(err))
  }, [dispatch])

  React.useEffect(() => {
    if (values.addressTemp) getAddress(values.addressTemp)
  }, [values.addressTemp, getAddress])

  const handleChangeIndex = (index: number) => {
    setTab(index)
  }

  const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const businessLogoDropzone = useDropzone({ accept: 'image/*', multiple: false })
  const businessCoverDropzone = useDropzone({ accept: 'image/*', multiple: false })

  const compressAndSetPreview = React.useCallback(
    (acceptedFiles, type) => {
      if (acceptedFiles) {
        dispatch({ type: Types.Loading, payload: { loading: true } })
        const file = acceptedFiles[0]
        const options = {
          maxSizeMB: 100,
          useWebWorker: true,
        }

        if (file) {
          imageCompression(file, options).then((image) => {
            setFieldValue(`${type}File`, image)
            setImagesPreview((prev) => ({
              ...prev,
              [type]: URL.createObjectURL(image),
            }))
            dispatch({ type: Types.Loading, payload: { loading: false } })
          })
        } else {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        }
      }
    },
    [dispatch, setFieldValue]
  )

  React.useEffect(() => {
    compressAndSetPreview(businessLogoDropzone.acceptedFiles, 'businessLogo')
  }, [businessLogoDropzone.acceptedFiles, compressAndSetPreview])

  React.useEffect(() => {
    compressAndSetPreview(businessCoverDropzone.acceptedFiles, 'businessCover')
  }, [businessCoverDropzone.acceptedFiles, compressAndSetPreview])

  React.useEffect(() => {
    if (hasErrorMessage([businessCoverDropzone, businessLogoDropzone])) {
      let message = 'Invalid File'
      if (businessCoverDropzone.fileRejections.length > 0) message = getErrorMessage(businessCoverDropzone)
      if (businessLogoDropzone.fileRejections.length > 0) message = getErrorMessage(businessLogoDropzone)
      AlertError(message)
    }
  }, [businessCoverDropzone, businessLogoDropzone])

  React.useEffect(() => {
    if (values._id && (values.businessLogo || values.businessCover)) {
      setImagesPreview({
        businessCover: values.businessCover,
        businessLogo: values.businessLogo,
      })
    }
  }, [values._id, values.businessCover, values.businessLogo])

  const getErrorMessage = (dropzoneFile: DropzoneState) => {
    return dropzoneFile.fileRejections[0].errors[0].message
  }

  const hasErrorMessage = (dropzoneFiles: DropzoneState[]) => {
    return dropzoneFiles.map((value) => value.fileRejections.length > 0).indexOf(true) > -1
  }

  return (
    <Box mt={3}>
      <AppBar position="static" color="default">
        <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="fullWidth">
          <Tab label="Details" />
          <Tab label="Logo & Cover" />
        </Tabs>
      </AppBar>

      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={tab} onChangeIndex={handleChangeIndex}>
        <TabPanel value={tab} index={0} dir={theme.direction}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Legal Business Name"
                    name="legalBusinessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.legalBusinessName}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Trading Name"
                    name="businessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessName}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Phone Number"
                    name="businessPhoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessPhoneNumber}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        format: '+61 ### ### ###',
                        mask: '_',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="ABN"
                    name="abn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.abn}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        format: '## ### ### ###',
                        mask: '_',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <InputText
                    select
                    errors={errors}
                    touched={touched}
                    label="Business Type"
                    name="businessType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessType}
                  >
                    <MenuItem value="">None</MenuItem>
                    {businessTypeList.map((bt: IBusinessType) => (
                      <MenuItem key={bt.name} value={bt.name}>
                        {bt.name}
                      </MenuItem>
                    ))}
                  </InputText>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Contact Name"
                    name="contactName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contactName}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Contact Email"
                    name="contactEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contactEmail}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Email to payment statement"
                    name="statementEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.statementEmail}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <InputText
                    disabled={!!values._id}
                    errors={errors}
                    touched={touched}
                    label="BSB"
                    name="bsb"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.bsb}
                    shrink={true}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        allowLeadingZeros: true,
                        format: '###-###',
                        mask: '_',
                        keepZeros: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <InputText
                    disabled={!!values._id}
                    errors={errors}
                    touched={touched}
                    label="Account Number"
                    name="accountNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.accountNumber}
                    InputProps={{
                      inputComponent: !values._id ? NumberFormatCustom : undefined,
                      inputProps: {
                        allowLeadingZeros: true,
                        keepZeros: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Autocomplete
                    options={addressList || []}
                    onChange={handleChangeAddress}
                    getOptionLabel={(option: IGoogleAddressResponse) => option.description || ''}
                    color="primary"
                    value={values.address as IGoogleAddressResponse}
                    renderInput={(params) => (
                      <InputText
                        {...params}
                        errors={errors}
                        touched={touched}
                        label="Address"
                        name="addressTemp"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Description"
                    name="businessDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessDescription}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.radioLabel}>
                      Bag Options
                    </FormLabel>
                    <RadioGroup
                      aria-label="bagOptions"
                      color="primary"
                      name="bringOwnBag"
                      value={values.bringOwnBag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {enumKeys(BringOwnBagEnum).map((key) => (
                        <FormControlLabel value={key} control={<Radio color="primary" />} label={BringOwnBagEnum[key]} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index={1} dir={theme.direction}>
          <div className={classes.uploadSection}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardHeader title="Business Logo" />
                  <CardContent className={classes.fileUpload}>
                    {imagesPreview.businessLogo ? (
                      <img src={imagesPreview.businessLogo} className={`${classes.preview} ${classes.logo}`} alt="logo" />
                    ) : (
                      <div className={`${classes.preview} ${classes.fileNotFound}`}>
                        <ImageTwoTone />
                      </div>
                    )}
                    <div {...businessLogoDropzone.getRootProps()} className={classes.container}>
                      <input {...businessLogoDropzone.getInputProps()} />
                      <Typography variant="body2">
                        Drag 'n' drop a file here <br /> or click to select files
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card>
                  <CardHeader title="Business Cover" />
                  <CardContent className={classes.fileUpload}>
                    {imagesPreview.businessCover ? (
                      <img src={imagesPreview.businessCover} className={classes.preview} alt="cover" />
                    ) : (
                      <div className={`${classes.preview} ${classes.fileNotFound}`}>
                        <ImageTwoTone />
                      </div>
                    )}
                    <div {...businessCoverDropzone.getRootProps()} className={classes.container}>
                      <input {...businessCoverDropzone.getInputProps()} />
                      <Typography variant="body2">
                        Drag 'n' drop a file here <br /> or click to select files
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </SwipeableViews>
    </Box>
  )
}

export default StoreForm
