import { MUIDataTableColumn } from 'mui-datatables'
import productPackageTypeEnum from 'src/enum/productPackageTypeEnum'
import productTypeEnum from 'src/enum/productTypeEnum'
import { getEnumValue } from 'src/helpers/enumHelper'
import { IStore } from 'src/types/User'

const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Grocer',
    name: 'grocer',
    options: {
      customBodyRender: (value: IStore) => `${value.businessName} - ${value.code}`,
    },
  },
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Type',
    name: 'type',
    options: {
      customBodyRender: (value: string) => getEnumValue(value, productTypeEnum),
    },
  },
  {
    label: 'Package Type',
    name: 'packageType',
    options: {
      customBodyRender: (value: string) => (value ? getEnumValue(value, productPackageTypeEnum) : '-'),
    },
  },
  {
    label: 'Category',
    name: 'category',
    options: {
      customBodyRender: (value: { name: string }) => (value ? value.name : '-'),
    },
  },
  {
    label: 'Active',
    name: 'isActive',
    options: {
      customBodyRender: (value: boolean) => (value ? 'Yes' : 'No'),
    },
  },
]

export default tableColumns
