import IUser from 'src/types/User'
import { api } from '.'

type IUserApi = {
  getById(id: string): Promise<IUser>
  update(user: IUser): Promise<IUser>
}

const UserApi = (): IUserApi => {
  const userApi = '/user'

  const getById = (id: string): Promise<IUser> => api.get(`${userApi}/${id}/bo`)
  const update = (user: IUser): Promise<IUser> => api.patch(`${userApi}/${user.id}/bo`, { ...user, id: undefined })

  return {
    getById,
    update,
  }
}

export default UserApi()
