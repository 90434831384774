import { IBusinessType } from 'src/types/BusinessType'
import { api } from '.'

type IBusinessTypeApi = {
  get(): Promise<IBusinessType[]>
}

const BusinessTypeApi = (): IBusinessTypeApi => {
  const businessTypeApi = '/business-type'

  const get = (): Promise<IBusinessType[]> => api.get(businessTypeApi)

  return {
    get,
  }
}

export default BusinessTypeApi()
