import BringOwnBagEnum from 'src/enum/BringOwnBagEnum'
import { IAddress, IGoogleAddressResponse } from './Address'

export interface IUser {
  id: string
  firstName: string
  lastName: string
  googleAddress?: string
  phoneNumber: string
  email: string
  address: Partial<IAddress>

  placeId?: string
  addressTemp?: string
}

export interface IStore {
  _id?: string
  businessName: string
  legalBusinessName: string
  contactName: string
  contactEmail: string
  statementEmail: string
  businessPhoneNumber: string
  abn: string
  businessDescription: string
  businessType: string
  active?: boolean
  businessLogoFile?: File
  businessCoverFile?: File
  documentIdentityFile?: File
  businessLogo?: string
  businessCover?: string
  address: IGoogleAddressResponse | IAddress
  bsb: string
  accountNumber: string
  bringOwnBag?: BringOwnBagEnum

  [key: string]: any
}

export const businessOwnerInitialValue: IUser = {
  id: '',
  firstName: '',
  lastName: '',
  googleAddress: '',
  phoneNumber: '',
  email: '',
  address: {
    description: '',
    placeId: '',
  },
}

export const userInitialValue: IUser = {
  id: '',
  firstName: '',
  lastName: '',
  googleAddress: '',
  phoneNumber: '',
  email: '',
  address: {
    postcode: '',
    suburb: '',
    state: '',
  },
}

export const storeInitialValue: IStore = {
  businessName: '',
  legalBusinessName: '',
  contactName: '',
  contactEmail: '',
  statementEmail: '',
  businessPhoneNumber: '',
  abn: '',
  businessDescription: '',
  businessType: '',
  address: {
    description: '',
    placeId: '',
  },
  bsb: '',
  accountNumber: '',
  addressTemp: '',
  bringOwnBag: BringOwnBagEnum.CUSTOMER_BAG,
}

export const bankAccountInitialValue: IBankAccount = {
  bsb: '',
  accountNumber: '',
}

export interface IBankAccount {
  bsb: string
  accountNumber: string
}

export const userFilterInitialValue: IUser = {
  name: '',
  familyName: '',
  phoneNumber: '',
  isActive: undefined,

  pendingReferences: undefined,
} as unknown as IUser

export default IUser
