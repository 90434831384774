import React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

interface Props {
  name: string
  touched?: any
  errors?: any
  select?: boolean
  inputRef?: any
  max?: number
  min?: number
  format: string
  mask?: string
  allowLeadingZeros?: boolean
  keepZeros?: boolean
  fixedDecimalScale?: boolean
  decimalScale?: number
  onBlur?(e: React.FocusEvent<any>): any
  onFocus?(e: React.FocusEvent<any>): any
  onChange?(e: React.ChangeEvent<any>): any
}

const NumberFormatCustom: React.FC<Props> = (props) => {
  const { inputRef, onChange, format, mask, allowLeadingZeros, keepZeros, fixedDecimalScale, decimalScale, ...other } = props

  const handleChange = (values: NumberFormatValues) => {
    if (values.floatValue !== null && values.floatValue !== undefined && onChange) {
      onChange({
        currentTarget: {
          name: props.name,
          value:
            props.max && values.floatValue > props.max
              ? props.max
              : props.min && values.floatValue < props.min
              ? props.min
              : keepZeros
              ? values.value
              : values.floatValue,
        },
      } as React.ChangeEvent<any>)
    } else if (!values.floatValue && onChange) {
      onChange({
        currentTarget: {
          name: props.name,
          value: '',
        },
      } as React.ChangeEvent<any>)
    }
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      allowNegative={false}
      allowEmptyFormatting={true}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      allowLeadingZeros={allowLeadingZeros}
      format={format}
      mask={mask}
      isNumericString={keepZeros}
    />
  )
}

export default NumberFormatCustom
