import { PersonTwoTone, ShoppingBasketOutlined, StoreTwoTone } from '@material-ui/icons'
import { Package } from 'react-feather'
import UserRoleEnum from 'src/enum/UserRoleEnum'

const items: { href: string; icon: any; title: string; roles: UserRoleEnum[] }[] = [
  {
    href: '/app/stores',
    icon: StoreTwoTone,
    title: 'Stores',
    roles: [],
  },
  {
    href: '/app/product',
    icon: Package,
    title: 'Products',
    roles: [],
  },
  {
    href: '/app/profile',
    icon: PersonTwoTone,
    title: 'Profile',
    roles: [],
  },
  {
    href: '/app/order',
    icon: ShoppingBasketOutlined,
    title: 'Orders',
    roles: [],
  },
]

export default items
