import { generateBodyForm } from 'src/helpers/ApiHelper'
import { IStore } from 'src/types/User'
import { api } from '.'

type IStoreApi = {
  get(): Promise<IStore[]>
  getById(id: string): Promise<IStore>
  changeStatus(id: string): Promise<{ success: boolean }>
  create(store: IStore): Promise<IStore>
  update(store: IStore): Promise<IStore>
  updateBankDetails(id: string, bankAccountToken: string, documentIdentityFile: File | undefined): Promise<any>
}

const StoreApi = (): IStoreApi => {
  const storeApi = '/store'

  const get = (): Promise<IStore[]> => api.get(storeApi)
  const getById = (id: string): Promise<IStore> => api.get(`${storeApi}/${id}`)
  const changeStatus = (id: string): Promise<{ success: boolean }> => api.post(`${storeApi}/${id}/status`)
  const create = (store: IStore): Promise<IStore> => api.post(storeApi, generateBodyForm(store))
  const update = (store: IStore): Promise<IStore> => api.patch(`${storeApi}/${store._id}`, generateBodyForm(store))
  const updateBankDetails = (id: string, bankAccountToken: string, documentIdentityFile: File): Promise<any> =>
    api.post(`${storeApi}/${id}/bank-account`, generateBodyForm({ bankAccountToken, documentIdentityFile }))

  return {
    get,
    getById,
    changeStatus,
    create,
    update,
    updateBankDetails,
  }
}

export default StoreApi()
