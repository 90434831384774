import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Facebook, Instagram, Twitter } from '@material-ui/icons'
import React from 'react'
import Page from 'src/components/Page'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 80px)',
  },
  image: {
    height: 250,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  storeContainer: {
    flexDirection: 'row',
  },
  socialMediaContainer: {
    width: '8em',
    marginTop: 5,
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}))

const Customer = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Become a partner">
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.container}>
          <img src="/static/t2_free_image1.png" alt="customer" className={classes.image} />
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <Typography variant="body1">Hi! Unfortunatelly this page is just for business that wants to sell their end of day products.</Typography>
          <Typography variant="body1">
            You can download our{' '}
            <b>
              <span className={classes.highlight}>customer app</span>
            </b>{' '}
            where you will be able to buy delicious items and help us reduce food waste ♥
          </Typography>
        </Grid>
        <Grid item xs={12} className={`${classes.container} ${classes.storeContainer}`}>
          <a href="https://play.google.com/store/apps/details?id=au.com.appspeople.grocer_customer_app">
            <img
              width="150px"
              height="58px"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a href="https://apps.apple.com/au/app/foody-bag/id1544043765">
            <img height="40px" alt="Download on the Apple Store" src="https://foodybag-assets.s3.ap-southeast-2.amazonaws.com/appstorebadge.png" />
          </a>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <Typography variant="body1">Don't forget to follow our social media!</Typography>
          <Grid container spacing={2} className={classes.socialMediaContainer}>
            <Grid item xs={4}>
              <a href="https://www.facebook.com/foodybagapp" target="_blank" rel="noreferrer">
                <Facebook />
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="https://twitter.com/foodybagapp" target="_blank" rel="noreferrer">
                <Twitter />
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="https://www.instagram.com/foodybagapp/" target="_blank" rel="noreferrer">
                <Instagram />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Customer
