import axios from 'axios'
import { IGoogleAddressResponse } from 'src/types/Address'
import AddressSearchResponse from 'src/types/AddressSearchResponse'
import { api } from '.'

type IAddressApi = {
  getByAddress(address: string): Promise<IGoogleAddressResponse[]>
  getByPostcode(postcode: string): Promise<{ data: AddressSearchResponse }>
}

const AddressApi = (): IAddressApi => {
  const adminApi = '/address'

  const getByAddress = (address: string): Promise<IGoogleAddressResponse[]> => api.get(`${adminApi}/${escape(address)}`)
  const getByPostcode = (postcode: string): Promise<{ data: AddressSearchResponse }> =>
    axios.get(`https://secure.geonames.org/postalCodeLookupJSON?postalcode=${postcode}&country=AU&username=grocer`, {})

  return {
    getByAddress,
    getByPostcode,
  }
}

export default AddressApi()
