import { Box, Button, Card, CardContent, Checkbox, Chip, Container, Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProductApi from 'src/api/product'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import { AlertSuccess } from 'src/components/Alert'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import { IProduct } from 'src/types/Product'
import { IStore } from 'src/types/User'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  storeList: {
    listStyle: 'none',
  },
  chip: {
    margin: 5,
  },
}))

const DuplicateProduct = () => {
  const classes = useStyles()
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()
  const params = useParams()
  const [storeList, setStoreList] = useState<IStore[]>([])
  const [selectedStores, setSelectedStores] = useState<string[]>([])
  const [product, setProduct] = useState<IProduct>({} as IProduct)

  const onSubmit = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.duplicate(params.id as string, selectedStores)
      .then(() => {
        AlertSuccess('Product duplicated successfully!')
        navigate('/app/product')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })

    StoreApi.get()
      .then((res) => {
        ProductApi.getById(params.id as string)
          .then((product) => {
            setStoreList(res.filter((store) => store._id !== product.grocerId))
            setProduct(product)
          })
          .catch((err: Error) => console.log(err))
          .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
      })
      .catch((err: Error) => console.log(err))
  }, [dispatch, params.id])

  const handleSelectStore = useCallback((e) => {
    const { target } = e

    setSelectedStores((prev) => {
      const storeIdIndex = prev.indexOf(target.name)
      if (storeIdIndex === -1) {
        return [...prev, target.name]
      } else {
        return prev.filter((id) => id !== target.name)
      }
    })
  }, [])

  const getStoreName = (id: string) => {
    const store = storeList.find((store) => store._id === id)
    if (!store) return ''
    return `${store.businessName} - ${store.suburb}`
  }

  return (
    <Page className={classes.root} title="Duplicate product to stores">
      <Container maxWidth={false}>
        <Toolbar
          title="Duplicate product to stores"
          showBack={true}
          buttons={
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={onSubmit}>
                Save
              </Button>
            </Box>
          }
        />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <h4>Product details</h4>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h4>Name</h4>
                      <p>{product.name}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <h4>Description</h4>
                      <p>{product.description}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <h4>Selected stores</h4>
                      <br />
                      {selectedStores.map((id: string) => (
                        <Chip className={classes.chip} key={id} label={getStoreName(id)} />
                      ))}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <h4>Select the stores you want to have the product</h4>
                  <br />
                  <ul className={classes.storeList}>
                    {storeList.map((store) => (
                      <li key={store._id}>
                        <Checkbox name={store._id} checked={selectedStores.indexOf(store._id as string) > -1} onClick={handleSelectStore} />
                        {store.businessName} - {store.suburb}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default DuplicateProduct
