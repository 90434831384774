import { Box, Button, Container, makeStyles } from '@material-ui/core'
import { Stripe } from '@stripe/stripe-js'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { validateABN } from 'src/helpers/ABNHelper'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IStore, storeInitialValue } from 'src/types/User'
import StoreForm from './StoreForm'
import UpdateBankAccountDialog from './UpdateBankAccountDialog'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const EditStore = () => {
  const classes = useStyles()
  const { state, dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()
  const params = useParams()
  const [open, setOpen] = React.useState<boolean>(false)

  const onSubmit = (values: IStore) => {
    if (!validateABN(values.abn.toString())) {
      AlertError('Invalid ABN')
      return
    }
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.update({ ...values, placeId: values.address.place_id })
      .then(() => {
        AlertSuccess('Store updated successfully!')
        navigate('/app/stores')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: storeInitialValue,
    onSubmit,
    validationSchema,
  }) as unknown as Formik<IStore>

  const { setValues } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.getById(params.id as string)
      .then((res) => {
        res.bsb = res.bsb ? res.bsb.replace(' ', '') : ''
        res.accountNumber = `*****${res.accountNumber ? res.accountNumber : ''}`
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  const handleChangeStatus = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.changeStatus(params.id as string)
      .then(() => {
        setValues((prev) => {
          AlertSuccess(`Store ${prev.active ? 'deactivated' : 'activated'}`)

          return {
            ...prev,
            active: !prev.active,
          }
        })
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitBankAccount = async (values: IStore) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })

    const { stripe }: { stripe: Stripe | null } = state
    let stripeToken = null
    if (stripe) {
      const result = await stripe.createToken('bank_account', {
        country: 'AU',
        currency: 'AUD',
        routing_number: values.bsb,
        account_number: values.accountNumber,
        account_holder_type: 'company',
        account_holder_name: formik.values.businessName,
      })
      if (result.error && result.error.message) {
        AlertError(result.error.message)
        dispatch({ type: Types.Loading, payload: { loading: false } })
        return
      }
      stripeToken = result.token
    }

    if (!stripeToken) {
      AlertError('Error while processing your bank account. Please contact support.')
      dispatch({ type: Types.Loading, payload: { loading: false } })
      return
    }

    if (!formik.values._id) {
      AlertError('Error while processing your request. Please refresh the page and try again.')
      dispatch({ type: Types.Loading, payload: { loading: false } })
      return
    }

    StoreApi.updateBankDetails(formik.values._id, stripeToken.id, values.documentIdentityFile)
      .then((res: IStore) => {
        res.bsb = res.bsb.replace(' ', '')
        res.accountNumber = `*****${res.accountNumber}`
        formik.setValues(res)
        AlertSuccess('Bank details updated successfully!')
        handleClose()
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  return (
    <Page className={classes.root} title="Edit Store">
      <Container maxWidth={false}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar
            title="Edit Store"
            showBack={true}
            buttons={
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
                <Button color="primary" variant="outlined" onClick={handleChangeStatus}>
                  {formik.values.active ? 'Deactivate' : 'Activate'}
                </Button>
                <Button color="primary" variant="outlined" onClick={handleClickOpen}>
                  Update Bank Account
                </Button>
              </Box>
            }
          />
          <StoreForm formik={formik} />
        </form>
        <UpdateBankAccountDialog open={open} handleClose={handleClose} onSubmit={handleSubmitBankAccount} />
      </Container>
    </Page>
  )
}

export default EditStore
