import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    '& img': {
      maxHeight: '100%',
      width: 45,
    },
    height: '100%',
  },
}))

const Logo: React.FC<{}> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img alt="Logo" src="/static/logo.svg" {...props} />
    </div>
  )
}

export default Logo
