import { Box, Button, Container, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProductApi from 'src/api/product'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import { AlertSuccess } from 'src/components/Alert'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IProduct, productInitialValue } from 'src/types/Product'
import { IStore } from 'src/types/User'
import ProductForm from './ProductForm'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const EditProduct = () => {
  const [storeList, setStoreList] = React.useState<IStore[]>([])
  const classes = useStyles()
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()
  const params = useParams()

  const onSubmit = (values: IProduct) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })

    ProductApi.update({
      _id: values._id,
      name: values.name,
      type: values.type,
      packageType: values.packageType,
      regularPrice: values.regularPrice,
      grocerPrice: values.grocerPrice,
      quantityAvailable: values.quantityAvailable,
      dailyLimit: values.dailyLimit,
      defaultQuantity: values.defaultQuantity,
      itemsInBag: values.itemsInBag,
      category: values.category,
      description: values.description,
      earliestToPost: values.earliestToPost,
      pictureFile: values.pictureFile,
    } as IProduct)
      .then(() => {
        AlertSuccess('Product updated successfully!')
        navigate('/app/product')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: productInitialValue,
    onSubmit,
    validationSchema,
  }) as unknown as Formik<IProduct>

  const { setValues } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.getById(params.id as string)
      .then((res: IProduct) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  const handleDuplicateClick = () => {
    navigate('duplicate')
  }

  React.useEffect(() => {
    StoreApi.get()
      .then((res) => setStoreList(res))
      .catch((err: Error) => console.log(err))
  }, [dispatch])

  const handleDeleteProduct = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.destroy(params.id as string)
      .then(() => {
        AlertSuccess('Product deleted successfully!')
        navigate('/app/product')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  return (
    <Page className={classes.root} title="Edit Product">
      <Container maxWidth={false}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar
            title="Edit Product"
            showBack={true}
            buttons={
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="outlined" onClick={handleDeleteProduct}>
                  Delete
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
                {storeList && storeList.length > 1 ? (
                  <Button color="primary" variant="contained" type="button" onClick={handleDuplicateClick}>
                    Duplicate To More Stores
                  </Button>
                ) : null}
              </Box>
            }
          />
          <ProductForm formik={formik} storeList={storeList} />
        </form>
      </Container>
    </Page>
  )
}

export default EditProduct
