import { ListItem, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { IconProps } from 'react-feather'
import { NavLink as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

const NavItem: React.FC<{ className?: string; href: string; icon: React.FC<IconProps>; title: string }> = ({
  className,
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
      <RouterLink
        className={({ isActive }: { isActive: boolean }) => `${isActive ? clsx([classes.button, classes.active]) : classes.button}`}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </RouterLink>
    </ListItem>
  )
}

export default NavItem
