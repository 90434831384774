import { ISignupRequest } from 'src/types/SignupRequest'
import { api } from '.'

type ISignupRequestApi = {
  create(signupRequest: ISignupRequest): Promise<{ success: boolean }>
}

const SignupRequestApi = (): ISignupRequestApi => {
  const signupRequestApi = '/signup-request'

  const create = (signupRequest: ISignupRequest): Promise<{ success: boolean }> => api.post(signupRequestApi, signupRequest)

  return { create }
}

export default SignupRequestApi()
