import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useRoutes } from 'react-router-dom'
import GlobalStyles from 'src/components/GlobalStyles'
import routes from 'src/routes'
import theme from 'src/theme'
import { AppProvider } from './AppContext'

function App() {
  const routing = useRoutes(routes)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppProvider>
        <HelmetProvider>{routing}</HelmetProvider>
      </AppProvider>
    </ThemeProvider>
  )
}

export default App
