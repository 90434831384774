import { IAuth, IAuthResponse } from 'src/types/Auth'
import { api } from '.'

type IAuthApi = {
  signin(user: IAuth): Promise<IAuthResponse>
  forgotPassword(user: IAuth): Promise<IAuthResponse>
  resetPassword(user: IAuth): Promise<IAuthResponse>
}

const AuthApi = (): IAuthApi => {
  const authApi = '/auth'

  const signin = (user: IAuth): Promise<IAuthResponse> => api.post(`${authApi}/signin`, user)

  const forgotPassword = (user: IAuth): Promise<IAuthResponse> => api.post(`${authApi}/forgot-password`, user)

  const resetPassword = (user: IAuth): Promise<IAuthResponse> => api.post(`${authApi}/reset-password-email`, user)

  return { signin, forgotPassword, resetPassword }
}

export default AuthApi()
