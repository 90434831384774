import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import UserApi from 'src/api/user'
import AppContext from 'src/AppContext'
import { Types } from 'src/reducers/AppReducer'
import IUser from 'src/types/User'
import NavBar from './NavBar'
import TopBar from './TopBar'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'scroll',
    marginTop: 64,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  content: {
    marginBottom: 30,
    width: '100%',
  },
}))

const DashboardLayout = () => {
  const classes = useStyles()
  const { state, dispatch } = React.useContext(AppContext)
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!state.isAuthenticated) {
      if (!localStorage.getItem('fbToken') || !localStorage.getItem('user')) {
        navigate(window.location.href.indexOf('dashboard') > -1 ? '/login' : `/login?url=${window.location.href}`)
      }

      dispatch({
        type: Types.RestoreLogin,
        payload: {},
      })
    }
  }, [state.isAuthenticated, navigate, dispatch])

  React.useEffect(() => {
    if (state.user && state.user.id && !state.user.email) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      UserApi.getById(state.user.id)
        .then((data: IUser) => {
          if (!data) navigate('/login')
          dispatch({ type: Types.SetUser, payload: { user: data } })
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    }
  }, [state.user, dispatch, navigate])

  if (!state.user || !state.user.email) return null

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
