import { Box, Container, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { CloudDownloadOutlined, FilterList } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router-dom'
import OrderApi, { IGetTotalsResponse } from 'src/api/order'
import AppContext from 'src/AppContext'
import CustomDatatable from 'src/components/CustomDatatable'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { initialOrderTotalResponse, inititalOrderBusinessResponse, IOrder, IOrderBusinessResponse, IOrderFilter } from 'src/types/Order'
import { PaginationDetails } from 'src/types/Paginate'
import OrderFilter from './OrderFilter'
import tableColumns from './tableColumns'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  line: {
    width: '100%',
  },
  pictureContainer: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    paddingRight: 15,
  },
  picture: {
    width: 60,
    [theme.breakpoints.up('lg')]: {
      width: 60,
    },
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  totals: {
    display: 'flex',
    '& div': {
      marginRight: 35,
    },
  },
}))

const Order = () => {
  const classes = useStyles()
  const [orderList, setOrderList] = React.useState<IOrderBusinessResponse>(inititalOrderBusinessResponse)
  const [totals, setTotals] = React.useState<IGetTotalsResponse>(initialOrderTotalResponse)
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()
  const [showFilter, setShowFilter] = React.useState<boolean>(false)
  const [downloadData, setDownloadData] = React.useState<IOrder[]>([])
  const csvLinkEl = React.useRef<any>()
  const [tableState, setTableState] = React.useState<PaginationDetails<IOrderFilter>>({
    page: 0,
    rowsPerPage: 10,
    sort: undefined,
    order: undefined,
    filter: undefined,
  })

  const handleToggleFilter = () => {
    setShowFilter((f: boolean) => !f)
  }

  const setFilter = React.useCallback(
    (filter: IOrderFilter) => {
      setTableState((prev) => ({ ...prev, filter, page: 0 }))
    },
    [setTableState]
  )

  const normalizePaginationData = React.useCallback(() => {
    return {
      ...tableState,
      filter: {
        ...tableState.filter,
        dateFrom: tableState.filter && tableState.filter.dateFrom ? moment(tableState.filter.dateFrom).startOf('day').toISOString() : undefined,
        grocerList:
          tableState.filter && tableState.filter.grocerList && tableState.filter.grocerList.length > 0 ? tableState.filter.grocerList : undefined,
      },
    }
  }, [tableState])

  const getData = React.useCallback(async () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    const promises: [Promise<IOrderBusinessResponse>, Promise<IGetTotalsResponse>] = [
      OrderApi.get(generatePaginationData(normalizePaginationData())),
      OrderApi.getTotals(generatePaginationData(normalizePaginationData())),
    ]

    const [orders, totals] = await Promise.all(promises)

    if (orders) setOrderList(orders as IOrderBusinessResponse)
    if (totals) setTotals(totals as IGetTotalsResponse)

    dispatch({ type: Types.Loading, payload: { loading: false } })
  }, [dispatch, normalizePaginationData])

  const handleDownloadCsv = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    OrderApi.downloadCsv(generatePaginationData(normalizePaginationData()))
      .then((data) => {
        setDownloadData(data.data)
        if (csvLinkEl && csvLinkEl.current) csvLinkEl.current.link.click()
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, csvLinkEl, normalizePaginationData])

  const headers = [
    { key: 'code', label: 'Code' },
    { key: 'grocerName', label: 'Store' },
    { key: 'completeName', label: 'Customer' },
    { key: 'orderTotal', label: 'Order Total' },
    { key: 'fees', label: 'Fees' },
    { key: 'gst', label: 'Gst/Fees' },
    { key: 'voucherUsed', label: 'Voucher' },
    { key: 'subTotal', label: 'Total' },
    { key: 'createdAt', label: 'Date' },
  ]

  React.useEffect(() => {
    getData()
  }, [getData])

  const customToolbar = () => {
    return (
      <>
        <Tooltip title={showFilter ? 'Hide Filter' : 'Show Filter'}>
          <IconButton onClick={() => handleToggleFilter()}>
            <FilterList />
          </IconButton>
        </Tooltip>
        <CSVLink data={downloadData} headers={headers} filename={'orders.csv'} ref={csvLinkEl} />
        <Tooltip title={'Download CSV'}>
          <IconButton onClick={handleDownloadCsv}>
            <CloudDownloadOutlined />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const handleClick = (id: string) => navigate(id)

  return (
    <Page className={classes.root} title="Order">
      <Container maxWidth={false}>
        <Toolbar
          title="Orders"
          buttons={
            <div className={classes.totals}>
              <div>
                <Typography variant="h5">Total Orders</Typography>
                <Typography variant="body1">$ {totals.orderTotal.toFixed(2)}</Typography>
              </div>
              <div>
                <Typography variant="h5">Total Vouchers</Typography>
                <Typography variant="body1">$ {totals.voucherUsed.toFixed(2)}</Typography>
              </div>
              <div>
                <Typography variant="h5">Total Fees</Typography>
                <Typography variant="body1">$ {totals.totalFees.toFixed(2)}</Typography>
              </div>
              <div>
                <Typography variant="h5">Total</Typography>
                <Typography variant="body1">$ {totals.subTotal.toFixed(2)}</Typography>
              </div>
            </div>
          }
        />
        <OrderFilter showFilter={showFilter} onSubmit={setFilter} />
        <Box mt={3}>
          <CustomDatatable
            list={orderList.data}
            count={orderList.total}
            tableColumns={tableColumns}
            serverSide
            filter={false}
            tableState={tableState}
            setTableState={setTableState}
            customToolbar={customToolbar}
            download={false}
            onClick={handleClick}
          />
        </Box>
      </Container>
    </Page>
  )
}

export default Order
