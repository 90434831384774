import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import AppContext from 'src/AppContext'
import { dismissAllAlerts } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { AuthInitialValue, IAuthResponse } from 'src/types/Auth'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 80px)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  illustration: {
    '& img': {
      width: '25rem',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formTitle: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
  },
  register: {
    '& span': {
      color: theme.palette.primary.main,
    },
    marginBottom: 45,
  },
}))

const Login = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = React.useContext(AppContext)
  const [error, setError] = React.useState<boolean>(false)

  const params = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const onSubmit = React.useCallback(
    (data: any) => {
      dismissAllAlerts()
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AuthApi.signin({ email: data.email, password: data.password })
        .then((data: IAuthResponse) => {
          setError(false)
          dispatch({
            type: Types.Authenticate,
            payload: {
              user: data.user,
              isAuthenticated: true,
              token: data.token,
            },
          })
          const urlRequested: string | null = params.get('url')
          navigate(urlRequested ? urlRequested.replace(window.location.origin, '') : '/app/stores', { replace: true })
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [navigate, dispatch, params]
  )

  const { errors, touched, values, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: AuthInitialValue,
    onSubmit,
    validationSchema,
  })

  React.useEffect(() => {
    if (error) resetForm()
  }, [error, resetForm])

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="xs" className={classes.container}>
          <div className={classes.formTitle}>
            <Typography variant="h3">Login to your account:</Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <InputText
              errors={errors}
              touched={touched}
              label="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
            />
            <InputText
              errors={errors}
              touched={touched}
              label="Password"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
            />

            <RouterLink to="/forgot-password">
              <Typography color="primary" variant="body1">
                Forgot password
              </Typography>
            </RouterLink>

            <Box my={2}>
              <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                Log in
              </Button>
            </Box>
          </form>

          <hr style={{ width: '100%', color: '#ddd', margin: '25px 0' }} />

          <Typography variant="body1" className={classes.register}>
            Doesn't have an account?
            <RouterLink to="/register">
              <span>{` Register`}</span>
            </RouterLink>
          </Typography>
        </Container>
      </Box>
    </Page>
  )
}

export default Login
