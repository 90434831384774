import { Box, Button, Container, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ProductApi from 'src/api/product'
import StoreApi from 'src/api/store'
import AppContext from 'src/AppContext'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IProduct, productInitialValue } from 'src/types/Product'
import { IStore } from 'src/types/User'
import ProductForm from './ProductForm'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const CreateProduct = () => {
  const [storeList, setStoreList] = React.useState<IStore[]>([])
  const classes = useStyles()
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()

  const onSubmit = async (values: IProduct) => {
    if (!values.pictureFile) {
      AlertError('Please, upload your product image')
      return false
    }

    if (values.grocerPrice >= values.regularPrice) {
      AlertError('Foody Bag Price must be lower than Regular Price')
      return false
    }

    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.create({ ...values })
      .then(() => {
        AlertSuccess('Product created successfully!')
        navigate('/app/product')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  React.useEffect(() => {
    StoreApi.get()
      .then((res) => setStoreList(res))
      .catch((err: Error) => console.log(err))
  }, [dispatch])

  const formik = useFormik({
    initialValues: productInitialValue,
    onSubmit,
    validationSchema,
  }) as unknown as Formik<IProduct>

  return (
    <Page className={classes.root} title="Create Product">
      <Container maxWidth={false}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar
            title="Create Product"
            showBack={true}
            buttons={
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
            }
          />
          <ProductForm formik={formik} storeList={storeList} />
        </form>
      </Container>
    </Page>
  )
}

export default CreateProduct
