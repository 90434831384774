import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import AppContext from 'src/AppContext'
import { AlertSuccess } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { AuthInitialValue } from 'src/types/Auth'
import validationSchema from './validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

const ResetPassword = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])
  const { dispatch } = React.useContext(AppContext)

  const onSubmit = React.useCallback(
    (data: any) => {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AuthApi.resetPassword({ ...data, resetPasswordToken: params.get('resetPasswordToken') })
        .then(() => {
          AlertSuccess('Password reset successfully')
          navigate('/login', { replace: true })
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [navigate, dispatch, params]
  )

  return (
    <Page className={classes.root} title="Reset Password">
        <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Container maxWidth="sm" className={classes.container}>
            <Formik initialValues={AuthInitialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box mt={3} mb={1}>
                    <Typography variant="h2">Reset Password</Typography>
                  </Box>

                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                  />
                  <InputText
                    errors={errors}
                    touched={touched}
                    label="Confirm Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                  />

                  <Box my={2}>
                    <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                      Save
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
    </Page>
  )
}

export default ResetPassword
