import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { useFormik } from 'formik'
import React from 'react'
import InputText from 'src/components/InputText'
import NumberFormatCustom from 'src/components/NumberFormatCustom'
import StripeLogo from 'src/components/StripeLogo'
import { bankAccountInitialValue } from 'src/types/User'
import validationSchemaBankAccount from './validationSchemaBankAccount'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const UpdateBankAccountDialog: React.FC<{ handleClose(): void; onSubmit(values: any): void; open: boolean }> = ({ handleClose, onSubmit, open }) => {
  const { errors, touched, handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: bankAccountInitialValue,
    onSubmit,
    validationSchema: validationSchemaBankAccount,
  })

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-labelledby="Update Bank Account">
      <form onSubmit={handleSubmit}>
        <DialogTitle disableTypography>
          <Typography variant="subtitle1">Update Bank Account</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputText
                errors={errors}
                touched={touched}
                label="BSB"
                name="bsb"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bsb}
                shrink={true}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    allowLeadingZeros: true,
                    format: '###-###',
                    mask: '_',
                    keepZeros: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                errors={errors}
                touched={touched}
                label="Account Number"
                name="accountNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accountNumber}
                shrink={true}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    allowLeadingZeros: true,
                    keepZeros: true,
                  },
                }}
              />
            </Grid>
          </Grid>
          <StripeLogo />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UpdateBankAccountDialog
